'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ToastContainer = undefined;

var _emotion = require('emotion');

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactTransitionGroup = require('react-transition-group');

var _ToastElement = require('./ToastElement');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var placements = {
  'top-left': { top: 0, left: 0 },
  'top-center': { top: 0, left: '50%', transform: 'translateX(-50%)' },
  'top-right': { top: 0, right: 0 },
  'bottom-left': { bottom: 0, left: 0 },
  'bottom-center': { bottom: 0, left: '50%', transform: 'translateX(-50%)' },
  'bottom-right': { bottom: 0, right: 0 }
};

var ToastContainer = exports.ToastContainer = function ToastContainer(_ref) {
  var children = _ref.children,
      placement = _ref.placement;
  return _react2.default.createElement(
    'div',
    {
      className: (0, _emotion.css)(_extends({
        boxSizing: 'border-box',
        maxHeight: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        padding: _ToastElement.gutter,
        pointerEvents: _react.Children.count(children) ? 'auto' : 'none',
        position: 'fixed'
      }, placements[placement]))
    },
    _react2.default.createElement(
      _reactTransitionGroup.TransitionGroup,
      { component: null },
      children
    )
  );
};